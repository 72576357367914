import firebase from "firebase/app";
import "firebase/firestore";

export type DocRef<T> = firebase.firestore.DocumentReference<T>;
export type ColRef<T> = firebase.firestore.CollectionReference<T>;

const { firestore } = firebase;

export class FirestoreUtils {
  /**
   * Generates a Firestore document id.
   */
  public static docId() {
    return firestore().collection("document").doc().id;
  }

  /**
   * Create a Firestore server timestamp sentinel.
   */
  public static timestamp(date?: Date): firebase.firestore.Timestamp {
    if (date) return firestore.Timestamp.fromDate(date);
    return firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
  }

  /**
   * Create an increment sentinel.
   *
   * @param amount The amount to increment by.
   */
  public static increment(amount: number): number {
    return (firestore.FieldValue.increment(amount) as unknown) as number;
  }

  /**
   * Create an array union sentinel to push an object into
   * a field on a document.
   *
   * @param elements List of elements to push.
   */
  public static push<T = firebase.firestore.FieldValue>(elements: T): T[] {
    return (firestore.FieldValue.arrayUnion(elements) as unknown) as T[];
  }

  /**
   * Create a sentinel to delete a field from a document.
   */
  public static delete<T = firebase.firestore.FieldValue>(): T {
    return (firestore.FieldValue.delete() as unknown) as T;
  }

  /**
   * Create a reference to a document.
   *
   * @param path The path to the document.
   */
  public static createDocRef<T>(path: string): DocRef<T> {
    return firestore().doc(path) as DocRef<T>;
  }

  /**
   * Create a reference to a collection.
   *
   * @param path The path to the document.
   */
  public static createColRef<T>(path: string): ColRef<T> {
    return firestore().collection(path) as ColRef<T>;
  }

  /**
   * Run a transcation.
   *
   * @param updateFunction The transaction to run.
   */
  public static runTransaction<T = unknown>(
    updateFunction: (transaction: firebase.firestore.Transaction) => Promise<T>
  ) {
    return firestore().runTransaction(updateFunction);
  }
}
